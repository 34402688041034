import React from "react";

const Header = () => {
  return (
    <header className="flex items-center justify-center">
      <img
        src={process.env.PUBLIC_URL + "/logo_b.png"} 
        alt="Logo"
        className="h-44 w-auto 2xl:h-28"
      />
    </header>
  );
};

export default Header;
