import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import MusicCard from "./components/MusicCard";
import "./styles.css";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="download" element={<MusicCard />} />
      </Routes>
    </>
  );
}

export default App;
