import React from "react";

const MusicCard = () => {
  return (
    <div className="bg-black">
      <div className="flex flex-col justify-center items-center h-screen">
        <img
          src={process.env.PUBLIC_URL + "luna.png"}
          alt="Capa da Música"
          className="mb-8 rounded-lg"
          style={{ height: "300px" }}
        />

        <h1 className="text-white text-3xl font-bold mb-4 text-center sm:text-4xl md:text-5xl lg:text-5xl md:max-w-2xl lg:max-w-3xl">
          Feid , ATL Jacob - LUNA (UNSILENCED Edit)
        </h1>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dropbox.com/scl/fi/mx6tpapox5gpbz4cxbeq6/Feid-ATL-Jacob-LUNA-UNSILENCED-Edit.mp3?rlkey=jg1b5ggsl0q9okznxmt4pfiih&dl=0"
          download
          className="bg-gradient-to-r from-purple-500 to-blue-500
        text-white px-14 py-4 rounded-md text-center md:py-2 sm:px-6 md:px-8 lg:px-10
        sm:text-lg md:text-xl lg:text-2xl uppercase font-bold mt-8"
        >
          Download
        </a>
      </div>
    </div>
  );
};

export default MusicCard;
