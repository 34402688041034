import React from "react";
import Header from "./Header";

const Home = () => {
  const backgroundStyle = {
    backgroundImage: 'url("hero_un2.jpg")',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100vh", // Altura da tela
    width: "100%", // Largura total
  };

  return (
    <div style={backgroundStyle}>
      <Header></Header>
    </div>
  );
};

export default Home;
